<script>
import Multiselect from "vue-multiselect";

import {
  broadcastMethods,
} from "@/state/helpers";

export default {
  data() {
    return {
      types: [
        {id: 'email', label: this.$t('broadcasts.channel_email')},
        {id: 'sms', label: this.$t('broadcasts.channel_sms')},
        {id: 'app_push', label: this.$t('broadcasts.channel_app_push')},
        {id: 'web_push', label: this.$t('broadcasts.channel_web_push')},
        {id: 'whatsapp', label: this.$t('broadcasts.channel_whatsapp')},
      ],
      
      auxType: null,
      auxBroadcast: null,
      broadcasts:[],
      filters:[],
      isOpen: null
    };
  },
  components:{Multiselect},
  props: {
    
  },
  mounted(){
    
  },
  computed: {
    
    
  },
  methods: {
    ...broadcastMethods,

    onFilterSelected(type){
      switch (type){
        case 'type':
          this.auxBroadcast = null;
          break;
        case 'broadcast':
          this.auxType = null;
          break;
      }
    },

    loadBroadcasts(){
			
      let loader = this.$loading.show();
      
      const params = {
				q : `where[status]=finished&where[project]=${localStorage.getItem("current_project")}&limit=100`
			}

			this.getBroadcasts(params).then((broadcasts)=>{
        if(broadcasts&& broadcasts.data){
          this.broadcasts= broadcasts.data;
          this.auxBroadcast = this.filters.filter(f=> f.key== 'broadcast').length > 0 ? this.broadcasts.filter(g=> g._id == this.filters.filter(f=> f.key== 'broadcast')[0].value)[0]  :null
        }
      }).catch(()=>{
        this.$notify({ type: 'error', text:this.$t('personalizations.get_broadcasts_error'),title:  this.$t('broadcasts.title') });
      }).finally(()=>{
        this.isLoading = false;
        loader.hide();
      });
    },

    removeAll(){
      this.auxType = null;
      this.auxBroadcast = null;
    },
    getAllFilters(){
      const filters= [];
      
      if(this.auxType){
        filters.push({
          key: 'type',
          value: this.auxType.id,
          badge: `${this.$t('filter.broadcast_type')}: ${this.auxType.label}`
        });
      }

      if(this.auxBroadcast){
        filters.push({
          key: 'broadcast',
          value: this.auxBroadcast._id,
          badge: `${this.$t('filter.broadcast')}: ${this.auxBroadcast.name}`
        });
      }
      
      return filters;
    },
    setAllFilter(filters){
      this.auxType = filters.filter(f=> f.key== 'type').length > 0 ? this.types.filter(g=> g.id == filters.filter(f=> f.key== 'type')[0].value)[0]  :null
      this.filters = filters;
      this.loadBroadcasts();
    }
  },
  watch:{
    
  }
};
</script>

<template>
  <div class="flex-grow-1 h-100 accordion" role="tablist">
    <div class="mb-2">
      <div class="badge bg-primary m-1" v-if="auxType">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t('filter.broadcast_type')}}: {{auxType?.label}}</span>
              <i v-on:click="auxType =null" class="fa fa-times interact"></i>
          </div>
      </div>
      <div class="badge bg-primary m-1" v-if="auxBroadcast">
          <div class="d-flex align-items-center justify-content-between">
              <span class="pe-2">{{$t('filter.broadcast')}}: {{auxBroadcast?.name}}</span>
              <i v-on:click="auxBroadcast =null" class="fa fa-times interact"></i>
          </div>
      </div>
    </div>
    <div class="mb-2">
      <div v-b-toggle="'accordion-2'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
        <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t('filter.broadcast_type')}}</button>
        <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
      </div>
        <b-collapse :id="`accordion-2`" role="tabpanel" class="mt-2" :visible="auxType!=null">
          <p class="small mb-1">{{$t('filter.broadcast_type_subtitle')}}</p>
          <multiselect 
            :options="types"
            :selectLabel="$t('common.select')"
            :deselectLabel="$t('common.deselect')"
            track-by="id" 
            label="label"
            :show-labels="false"
            :multiple="false"
            v-model="auxType"
            :allow-empty="false"
            @input="onFilterSelected('type')">
          </multiselect>
        </b-collapse>
    </div>
    <div class="mb-2">
      <div v-b-toggle="'accordion-3'" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 45px;" role="tab">
        <button   class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto">{{$t('filter.broadcast')}}</button>
        <i class="ml-auto text-end bx bx-chevron-down interact me-1 font-size-18" ></i>
      </div>
        <b-collapse :id="`accordion-3`" role="tabpanel" class="mt-2" :visible="auxBroadcast!=null">
          <p class="small mb-1">{{$t('filter.broadcast_subtitle')}}</p>
          <multiselect 
            :options="broadcasts"
            :selectLabel="$t('common.select')"
            :deselectLabel="$t('common.deselect')"
            track-by="_id" 
            label="name"
            :show-labels="false"
            :multiple="false"
            v-model="auxBroadcast"
            :allow-empty="false"
            @input="onFilterSelected('broadcast')">
          </multiselect>
        </b-collapse>
    </div>
  </div>
</template>
